import React from "react";
import { Layout } from "~/templates/Layout";

const NotFoundPage: React.FC<{}> = () => (
  <Layout>
    <h1>NOT FOUND</h1>
  </Layout>
);

export default NotFoundPage;
